import { render, staticRenderFns } from "./MediWidgetContainer.vue?vue&type=template&id=a4c5378c&scoped=true&"
import script from "./MediWidgetContainer.vue?vue&type=script&lang=js&"
export * from "./MediWidgetContainer.vue?vue&type=script&lang=js&"
import style0 from "./MediWidgetContainer.vue?vue&type=style&index=0&id=a4c5378c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4c5378c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MediRadioSwitcher: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/Medi/MediRadioSwitcher.vue').default,MediWidgetSelect: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/Medi/Widget/MediWidgetSelect.vue').default,BaseIcon: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseIcon.vue').default,MediWidgetButton: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/Medi/Widget/MediWidgetButton.vue').default,BaseTabsItem: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/tabs/BaseTabsItem.vue').default,HeaderSearch: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/Header/Search/HeaderSearch.vue').default,MediTabs: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/Medi/Tabs/MediTabs.vue').default})
